@import url(https://fonts.googleapis.com/css2?family=Trirong&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}
.Footer{
  position: -webkit-sticky;
  position: sticky;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.Block{
  width: 50vh;
    height: 100vh;
    background-color: black;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.Backscreen{
  background-color: #f2f2f2;
 min-height: auto;
  max-width:1060;
  display: flex;
  flex-direction: column;
    
}

.BackscreenInterscroller{
  background-color: #fafafa;
 max-height: 1230px;
  max-width:1060;
  display: flex;
  flex-direction: column;
    
}

.BackscreenInterscroller2{
  background-color: #fafafa !important; 
  min-height:auto !important;
   max-width:1060px !important;
   margin-top: 470px;
   
  
}

.Logo{
-webkit-transform: scale(0.5);
        transform: scale(0.5);
display: flex;
align-items: center;

   justify-content: center;
}

.DivLogo{
  width: 100%;
  height: auto;
  
}


.Test{
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  
}

.TextaboveLine{
  margin-top: 50px;
  font-size: 10pt;
  font-weight:700;
  color: black !important;
  width: 100%;
  text-transform: uppercase;
}

.Thickline{
  border: 2px solid black
}

.TextaboveLine2{
 /* font-size: 1.4vw; */
 font-size: 10pt;
  float: right;
  font-weight:bold;
  color: black !important;
  text-transform: uppercase;
  padding-top: 10px;
}

.TextaboveLine3{
  margin-top: 50px;
  font-size: 11pt;
  font-weight:normal;
  color: black !important;
}

.Minilogo{
  max-width: 25px;
  max-height: 25px;
}

.Image{
  
  width: 100%;
  height: 'auto';
  margin-top: 40px;
     
  }

  .Panel{
    border: 2px solid #d2d2d2 !important;
    width: 100%;
    height: 45px;
    margin: 0 auto;
    margin-top: 5%;
  }

  .ABO{
    background-color: #ee7f00 ;
    color: white;
    float: left;
    height: 25px;
    font-weight: bold;
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
    display: none;
  }
  .HamburgerMenu{
    float: right;
    margin-top: 25px;
    display: none;
    margin-bottom: 10px;
    width: 100%;
    opacity: 0;
  }
  .SkyscrapperMobile{
    display: none;
  }

  .MediumRectangleMobile{
    display: none;
  }

  .Interscroller{
    display: none;
  }

  .DesktopAds{
    display: block;
  }

  .Menu{
    position: absolute;
    top: 45%;
    
  }
  .Name{
    position: absolute;
    width: 100%;
    height: 100vh;
    align-items: center;
   display: flex;
   justify-content: center;
   flex-direction: column;
  
  }

  .Name p{
    position: absolute;
    font-size: 2vw;
    font-family: 'Trirong', serif;
    top: 28%;
  }
  .Name .Title{
    position: absolute;
    font-size: 3vw;
    font-family: 'Trirong', serif;
    top: 0;
    color:#ee7f00 
  }
  .Name .Bottom{
  
    font-size: 1vw;
    font-family: 'Trirong', serif;
   
  }

  @media  (max-width: 768px) {
    .Backscreen{
      background-color: #f2f2f2;
      height: 100vh;
      width:100%,
    }

    .ABO{
      background-color: #ee7f00 ;
      color: white;
      float: left;
      width: 100%;
      font-weight: bold;
      width: 50px;
      display: inline;
    }
    .HamburgerMenu{
      float: right;
      margin-top: 15px;
      opacity: 1;
      width: 100%;
    }

    .Logo{
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
      display: flex;
      width: 100%;
      height: auto;
      justify-content: center;
       
      }

      .DivLogo{
        width: 70%;
        height: auto;
         
      }

      .First3Picturies{
        display: none;
      }



      .TextaboveLine{
        margin-top: 50px;
        font-size: 8pt;
        font-weight:700;
        color: black !important;
        
      }
      
      .Thickline{
        border: 1px solid black
      }
      
      .TextaboveLine2{
        font-size: 5.5pt;
        float: right;
        font-weight:bold;
        color: black !important;
        text-transform: uppercase;
        padding-top: 10px;
      }
      
      .TextaboveLine3{
        margin-top: 50px;
        font-size: 11pt;
        font-weight:normal;
        color: black !important;
      }

      .Pictures4{
        display: none;
      }

      .HideElement{
        display: none;
      }

     .Test{
       margin-left: 10%;
     }

     .DesktopAds{
      display: none;
    }
  
    
  }

  

  @media  (max-width: 480px) {
    
    .DesktopAds{
      display: none;
    }
    
    .Interscroller{
      display: flex;
      flex-direction: column;
      height:490px;
      width: 100%;
    }

     .SkyscrapperMobile{
      display: flex;
      justify-content: center;
      

    }
    .Skyscrapper{
      display: none;
    }

    .MediumRectangleMobile{
      display: block;
      justify-content: center;
    }


    
  }

  .Butto{
    margin-top: 200px;
  }

  .Impressum{
   
    font-size: 1vw;
    font-family: 'Trirong', serif;
    color: black;
    font-weight: bold;
   
  }

  .Texto{
    width: 100%;
    margin-top: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
/*neue css*/
.SidebarImg{
    width: inherit;
}





.Backscreen{
  
}

.Container{
    /*1020*/
    max-width: 1020px;
    background-color: white;
    position: relative;
    
   
    /*clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 0% 50%)*/
}

.ContainerInterscroller{
    /*1020*/
    max-width: 1020px;
    background-color: transparent;
    position: relative;
    height: 130vh;
   
    /*clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 0% 50%)*/
}

.ContainerRollover{
    max-width: 1020px;
    background-color: white;
    position: relative;
    margin-top: 10%;
    transition: 2s;
}

.ContainerDoubleSide{
    max-width: 820px;
    background-color: white;
    position: relative;
    
    transition: 2s;
}


.ButtonSubscribe{
    position: relative;
    bottom: 130px;
    left: 30px;
}

.slider {
    position: relative;
    -webkit-appearance: none;
    width: 80%;
    height: 4px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 1;
    bottom: 140px;
    left: 30px;
}



.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: black;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: black;
    color: black;
    cursor: pointer;
}



.LinkColor{
    color: black !important;
    
}


.HeadColor{
    color:#ee7f00 !important;
    font-size: 10pt !important;
    
}

.HeadContainer{
    height: auto!important;
    
    overflow:hidden;
   
    cursor: pointer !important;
}

.AboveHeadColor{
    color:#ee7f00 !important;
    cursor: pointer !important;
    font-size: 10pt;
}

.More{
    color: #ee7f00 !important;

    margin-left: 1% !important;
    font-size: 14px !important;
    
}

.Author{
    color:#b1b1b0 !important;
    font-size: small  !important;
}
.BodySize{
    min-height:auto !important;
    display: flex !important;
    justify-content: center;
    pointer-events: none;
    
}

.BodyLinks{
    min-height:auto !important;
    display: flex !important;
    justify-content: center;
    width: 100%;
   pointer-events: none;
}



.DescriptionContainer{
    height: auto !important;
  
    overflow:hidden;
    
   cursor: pointer !important;
   
}



.ColorandSize{
    color: black  !important;
    font-size: 10.3pt !important;
    font-weight: bold  !important;
    
}

.LinkColor:hover{
    cursor: pointer !important;
}

.Display{
    min-height: 0px  !important;
    
   

  }

  .BlockText{
      color: black !important;
      font-size: 14px !important;
  }


.ButtonsPosition{
    margin-top: 40px;
}

.Mediaplayer{
    margin-top: 70px;
    border: 2px solid #ee7f00 !important;
    max-width: 65% !important;
    height: 255px !important;
    
  
}

.MediapayerHead{
    position: relative;
    font-size: x-small;
    text-transform: uppercase;
    color: #ee7f00;
    font-weight: bold;
    margin-left: 100px;
    bottom: 80px;
    width: 380px;
}

.MediapayerText{
    position: relative;
    font-size:medium;
    text-transform: uppercase;
    color: black;
    font-weight: bold;
    margin-left: 100px;
    bottom: 90px;
    width: 380px;
}

.Mediaplay{
    
    color:#ee7f00 !important;  
}

.Mediapicture{
    position: relative;
    height: 90px;
    width: 90px;
   
    bottom: 180px;
}


.Newsticker{
    border: 2px solid #b9b9b9 !important;
    margin-left: 30px;
    width: 300px !important;
    height: 490px !important;
    margin-top: 10%;
    overflow:scroll;
    overflow-x: hidden;
    cursor: pointer;
    
}


.Newsticker2{
    border: 2px solid #b9b9b9 !important;
    margin-left: 30px;
    width: 300px !important;
    height: 610px !important;
    margin-top: 10%;
    overflow:scroll;
    overflow-x: hidden;
    cursor: pointer;
    
}

.Newsticker3{
    border: 2px solid #b9b9b9 !important;
    margin-left: 30px;
    width: 300px !important;
    height: 390px !important;
    margin-top: 10%;
    overflow:scroll;
    overflow-x: hidden;
    cursor: pointer;
    
}

.SidebarPicker{
    border-bottom: 2px solid #b9b9b9 !important;
    height: 50px;
    width: 298px !important;
    display: flex;
    align-items: center;
   
 
}
.SidebarPicker2{
    border-bottom: 2px solid #b9b9b9 !important;
    height: 50px;
    width: 298px !important;
  
   
 
}

.HeadWords{
    display: flex;
    width: 60%;
    font-weight: 700;
    font-size: 0.75em;
    justify-content: space-around;
}

.ThreebarsPicker{
    border-bottom: 2px solid #b9b9b9 !important;
    height: 50px;
    width: 100% !important; /*288px*/
}

.HeadWords2{
    display: flex;
    flex-direction: row;
    
    margin-left:15px;
    font-weight: 700;
    font-size: 10pt; /*0.9em*/
    color: black;
}

.HeadWords:hover{
    cursor: pointer;
    
}

.Text{
    font-weight: bold;
    font-size: 0.8em;
    color: black;
    
}

.ThreeBarsText{
    font-weight: bold;
    font-size: 10pt;
    color: black;
    
    
}

.ThreeBarsWeiterArtikels{
    font-weight: bold;
    font-size: 0.8em;
    color: black;
    text-align: center;
}

.Text2{
    font-weight: bold;
    font-size: 0.85em;
    
}

.Text3{
    
    font-size: 0.9em;
    
}

.ThreeBarsHead{
    font-weight: bold;
    font-size: 9pt;
    color: #ee7f00;
    height: 5px;
    margin-top: 10px;
    
}

.Textblock{
    width: 75% !important; /*240px*/
    min-height: 50px;
    margin-left: 10px;
}

.WahlPict{
    margin-top: 5%;
    margin-left: 10%;
}

.ElectionFrame{
    height: 100%;
    width: 100%;
    pointer-events: none;
    cursor: pointer;
    
    
}


.LargePict{
   max-width: 620px;
   max-height: auto;
   margin-top: 6%;
   display: flex !important;
   pointer-events: none;
   
}


.OnlyPicture{
    width: 100%;
   height: auto;
   margin-top: 6%;
   
}

.PictPosition{
    margin-top: 2%;
}

.LineColor{
    color: #b9b9b9;
    
}

.SideBar{
    
   
   
  
}


.Smallnewsticker{
    border: 2px solid #b9b9b9 !important;
    
    width: 300px !important;
    min-height: 279px !important;
    margin-top: 10%;
    margin-bottom: 10%;
   margin-left:30px;
  
}


.Widget{
    max-width: 620px;
    max-height: 642px;
    pointer-events: none;
    display: flex !important;
    background-color: #f2f2f2;
}

.Video{
  
    width: 100%;
    height: auto;
}

.Chart{
    width: 100%;
    height: 450px;
    pointer-events: none;
    
}

.Btnback{
   
    background-color: #ee7f00;
}

.ThreeBars{
    float: left;
    border: 2px solid #b9b9b9 !important;
    width: 290px !important;
    min-height: 379px !important;
    margin-top: 10%;
    margin-left:17px;
  
}

.SecondThreeBars{
    float: left;
    border: 2px solid #b9b9b9 !important;
    width: 30% !important; /*290px*/
    min-height: 309px !important;
    margin-top: 2%;
    margin-left:17px;
}

.Test{
    display: flex;
    flex-direction: row;
}


.ColorofLinks{
    color: black !important;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    
}

.SizeofLinks{
    width: 60% !important; /*140px*/
   margin-top: 25px;
  margin-right: 140px !important;
  
  
}

.LinksColorandSize{

    color: #b9b9b9 !important;
    font-size: 10pt;
    font-weight: normal;
   
}




@media  (max-width: 768px) {
    .More{
        color: #ee7f00 !important;
    
        margin-left: 1% !important;
        font-size: 10px !important;
        
    }
    .PictrueMobile{
        margin-top: 0;
        
    }

    .Author{
        color:#b1b1b0 !important;
        font-size:10px  !important;
    }
    .BodySize{
        min-height:auto !important;
        display: flex !important;
        flex-direction: column-reverse;
        
        
    }


    .ColorandSize{
        color: black  !important;
        
        font-weight: normal  !important;
        
    }
    
    .HeadColor{
        color:#ee7f00 !important;
        font-size: 10pt !important;
        
    }

    .HeadContainer{
        height: 55px !important;
        
        overflow:hidden;
       
        cursor: pointer !important;
    }

    .SideBar{
    
        width: 300px;
        float: right;
        margin-top: 4%;
        display: none;
    }


    .LargePict{
        display: none;
        
     }

     .BlockText{
        color: black !important;
        font-size: 14px !important;
        display: none;
    }

  
    .More{
        color: #ee7f00 !important;
    
        margin-left: 1% !important;
        font-size: 14px !important;
        display: none;
    }

    .Mediaplayer{
      display: none;
    }

    .Chart{
        width: 100%;
        height: auto;
        pointer-events: none;
        
    }

    
  }
  
  @media  (max-width: 480px) {
    .HeadColor{
        color:#ee7f00 !important;
        font-size: 8.5px !important;
    }

    .ColorandSize{
        color: black  !important;
        font-size: 7.5pt !important;
      
        
    }
    
    .DescriptionContainer{
        height: auto !important;
      margin-top: 10px;
        overflow:hidden;
        text-overflow: ellipsis    !important;
       white-space: pre-line;
       cursor: pointer !important;
       
    }

    .HeadContainer{
        height: 20px !important;
        overflow: hidden;
        cursor: pointer;
    }

  }


  @media  (max-width: 1023px) {
    .SideBar{
    
        width: 300px;
        float: right;
        margin-top: 4%;
        
        display: none;
    }
  }
  

 
.Minilogo{
  height: 18px;
  width: 18px;
  margin-bottom: -5px;
  margin-right: 5px;
}


.Head{
    
  display: flex;
  justify-content: center
    
   
}

.Date{
  position: relative;
  color: gray;
  cursor: pointer;

}

.NavPos{
  margin-top: -20px;
  margin-left: 80px;
}

.HeadSize{
  max-height: 1012px;
  word-break:break-all;
}
.Login{
  
   max-width: 100%;
  
  
}
.Abo{
  
  background-color: #ef7100;
  color: white;
  text-align: center;
  width: 40px;
  float: right;
 
}



.List{
    display: inline-flex;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}



.Minilogo{
  max-height: 20px;
  max-width: 20px;
  
}

.Navleft{
  float: left;
}

.Searchfield{
  max-width: 70px;
  max-height: 30px;
 

}

.PictureSize{
  max-width: 182px !important;
  max-height: 27px !important;
}

.FootLink{
  margin-top: 40px;
  width: 100% !important;
  float: left;
}

.SizeofText{
  color: black !important;
  font-size: 13px !important;
  pointer-events: none;
  width: 80%;
    margin-top: 20px;
}
.NavFoot{
  width: 100%;
 height: auto;
 word-break:break-all;
}
/*////////////////////////////////////////////////////////////////////////*/


@media  (max-width: 768px) {

  .NavFoot{
   display: none;
  }

  .HeadSize{
   display: none;
  }
  .Head{
    

    display: flex;
    justify-content: center
      
     
  }
  
  .Login{
     
     max-width: 100%;
    
    
  }
  .Abo{
    
    background-color: #ef7100;
    color: white;
    text-align: center;
    width: 40px;
    float: right;
   
  }
  


  
  
  .List{
      display: inline-flex;
      color: white;
      text-align: center;
      padding: 14px 16px;
      text-decoration: none;
  }
  
  .Date{
    float: left;
    
  }
  .Minilogo{
    max-height: 20px;
    max-width: 20px;
    
  }
  
  .Navleft{
    float: left;
  }
  
  .Searchfield{
    max-width: 70px;
    max-height: 30px;
   
  
  }
  
  .PictureSize{
    max-width: 182px !important;
    max-height: 27px !important;
    margin-top: 40px !important;
  }
  
  .FootLink{
    margin-top: 40px;
    width: 100% !important;
    float: left;
  }
  
  .SizeofText{
    color: black !important;
    font-size: 13px !important;
    margin-top: 40px !important;
    width: 100% !important;
    float: left;
    pointer-events: none;
  }

  
}

/*New Styles updated*/

.Nav{
  
  width: 100%;

    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
}

.Category{
  display: flex;
  width: 55%;
  justify-content: space-evenly;
  color: gray;
  cursor: pointer;

  
}

.Buttons{
  display: flex;
  width: 10%;
  justify-content: space-between;
  cursor: pointer;
}

.LoginBtn{
  color: orange;
  cursor: pointer;
}

.LoginBtn:hover{
  text-decoration: underline;
  }

#Newsletter:hover{
text-decoration: underline;
}
#Podcasts:hover{
  text-decoration: underline;
}
#Club:hover{
  text-decoration: underline;
}
#ePaper:hover{
  text-decoration: underline;
}
#Archiv:hover{
  text-decoration: underline;
}
#Shop:hover{
  text-decoration: underline;
}
#Jobs:hover{
  text-decoration: underline;
}
#Inside:hover{
  text-decoration: underline;
}
.Footer{
    width: 100%;
    height: auto;
  
}

.Display{
   
    /*
      .Backscreen{
  background-color: #f2f2f2;
  min-height: 100vh;
  max-width:1060;
  display: flex;
    flex-direction: column;
    background-image: url('components/ads/components/Rollover.jpg');
    
    background-attachment: fixed;
    background-repeat: no-repeat;
 */
 width: 100%;
height: 3065vh;
    display: block;
    justify-content: center;
    position: absolute;
    
    background-image: url(/static/media/Rollover.67e88c78.jpg);
   background-size: cover;
    background-attachment:fixed !important
  }

  

  .Rollover{
      width: 100%;
      height: auto;
     
   
    
  }

  .DoppelSideBarRightpict{
  
    width: 50vh;
    height: 100vh;
    
   
    
}

.DoppelSideBarRight{
    
    
    width: 100%;
    
   position:-webkit-sticky;
    
   position:sticky;
   top:0;
}

.DoppelSideBarRight1Lay{
    position: absolute;
    right: 0;
    height: 21000px;
    pointer-events: none;
  
}


.DoppelSideBarLeftpict{
  
    width: 50vh;
    height: 100vh;
   
    
}



.DoppelSideBarLeft{
    
    
    width: 100%;
    height: auto;
   position:-webkit-sticky;
   position:sticky;
   top:0;
}

.DoppelSideBarLeft1Lay{
    position: absolute;
    left: 0;
    height: 21000px;
    pointer-events: none;
}



.Sidebar1Lay{
    position: absolute;
    right: 0;
    height: 21000px;
}

.Sidebar{

    width: 100%;
    height: auto;
   position:-webkit-sticky;
   position:sticky;
   top:0;
}

.Sidebarpict{
    width: 100%;
    height: 100vh;
}


.RectangleDisplay{
   position: absolute;
   height: 80px;
}

.RectanglePict{
    width: 100%;
    height: inherit;
}


.MobileInterscroller{
    height:100vh;
    width: 100%;
    position: relative;
    
   
  
   
    /*clip-path: polygon(0% 0%, 100% 0%, 100% 55%, 0% 55%);*/
    
    }
    .MobileInterscrollerPict{
        position: fixed;
        top: 15%;
        z-index: -2;
        background-image: url(/static/media/Apple_Mobile_interscroller.2d3642da.jpg);
       
        background-repeat: no-repeat;
        background-size:contain;
         height: inherit;
        width: inherit;
    }

    .MobileInterscrollerPict2{
        position: fixed;
        top: 15%;
        z-index: -2;
        background-image: url(/static/media/Apple_Mobile_interscroller2.71a5dda5.jpg);
       
        background-repeat: no-repeat;
        background-size:contain;
         height: inherit;
        width: inherit;
    }
   

@media  (max-width: 480px) {


    

    .Takeover1Lay{
        position: relative;
        height: auto;
        width: 100%;
    }

    .Takeover{
        position: relative;
        width: 100%;
        height: auto;
        margin-top: 50px;
        display: flex;
        justify-content: center;
    }
    
    
    
    .Takeoverpict{
        width: 300px;
        height: 250px;
    }

    .RectangleDisplay{
        display: none;
     }

     


       .Display{
        display: none;
      }
    
      .UniCreditMini{
        display: none;
      }
    
      .Rollover{
        display: none;
         
       
        
      }
    
      .DoppelSideBarRightpict{
      
        display: none;
       
        
    }
    
    .DoppelSideBarRight{
        
        
        display: none;
    }
    
    .DoppelSideBarRight1Lay{
        display: none;
    }
    
    
    .DoppelSideBarLeftpict{
      
        display: none;
        
    }
    
    .DoppelSideBarLeft{
        
        
        display: none;
    }
    
    .DoppelSideBarLeft1Lay{
        display: none;
        
    }
    
    
    
    .Sidebar1Lay{
        display: none;
    }
    
    .Sidebar{
    
        display: none;
    }
    
    .Sidebarpict{
       display: none;
    }
    


  }

  @media (max-width:768px){
      .Name{
          display:none;
      }
  }
.MainArticle{width:inherit;max-height:526px;height:100%;display:flex;cursor:pointer}.ArticleText{position:relative;width:50%}.ArticleImg{max-height:520px;max-width:100%;width:100%;height:520px;position:relative}.ArticleImg img{width:95%;position:absolute;right:0;top:70px}.ArticleDesr{font-family:"Roboto",sans-serif;font-weight:bold;font-size:1.6rem;margin-top:70px}.ArticleDesr:hover{text-decoration:underline;-webkit-text-decoration-color:orange;text-decoration-color:orange;text-decoration-thickness:5%}.ArticleContent{font-size:.9rem;font-weight:500}.ArticleIcons{display:flex;margin-top:25px}.IconandText{display:flex;font-size:12px;color:gray;margin-left:8px}.ThreeArticlesTakeover{display:flex;flex-direction:row;flex-wrap:nowrap;justify-content:space-between;align-items:baseline}
.threeArticles{width:90%;cursor:pointer}.Img{width:inherit}.thrArtcImg{width:inherit;height:160px}.Title{width:70%;font-weight:800}.Desription{margin-top:20px;width:75%}.MainArticles{opacity:1;width:100%;display:flex;flex-direction:column;margin-top:20px;cursor:pointer}.MainImg{opacity:1;width:inherit}.ImgSmall{opacity:1;width:inherit;margin-left:30px}.Img{opacity:1;width:97%}.MainTitle{opacity:1;width:70%;font-weight:bold;font-size:27px;cursor:pointer}.MainTitle:hover{text-decoration:underline;-webkit-text-decoration-color:orange;text-decoration-color:orange;text-decoration-thickness:5%}.MainContent{opacity:1;width:70%;font-size:15px;margin-top:10px;cursor:pointer}.SmallArticle{opacity:1;display:flex;flex-direction:row-reverse;margin-top:20px;cursor:pointer}.ImgSmallArticle{width:80%}.SmallMainTitle{opacity:1;font-weight:bold;font-size:27px}.SmallMainTitle:hover{text-decoration:underline;-webkit-text-decoration-color:orange;text-decoration-color:orange;text-decoration-thickness:5%}.SmallMainContent{opacity:1;margin-top:10px}.TitleandContent{display:flex;flex-direction:column;justify-content:space-around;width:70%}.HeadScroll{width:100%;height:100px;background:#fff;position:-webkit-sticky;position:sticky;z-index:1;top:0;display:none;justify-content:space-between;align-items:center;box-shadow:0px 2px 4px;-webkit-transform:translateY(-200px);transform:translateY(-200px)}.MenuHome{width:10%;display:flex;justify-content:space-evenly;cursor:pointer}.SocialMedia{width:15%;display:flex;justify-content:space-evenly;cursor:pointer}.PersonSearch{width:10%;display:flex;justify-content:space-evenly;cursor:pointer}.ImgHead{cursor:pointer}.Links{width:100%;height:auto;display:flex;justify-content:space-around;font-size:13px;color:gray}.Column{display:flex;flex-direction:column;justify-content:space-evenly;cursor:pointer}.Headline{text-transform:uppercase;color:#000;font-weight:bold;font-size:20px;padding-bottom:25px}#column{margin-top:55px}
