.App {
  text-align: center;
}
.Footer{
  position: sticky;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Block{
  width: 50vh;
    height: 100vh;
    background-color: black;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.Backscreen{
  background-color: #f2f2f2;
 min-height: auto;
  max-width:1060;
  display: flex;
  flex-direction: column;
    
}

.BackscreenInterscroller{
  background-color: #fafafa;
 max-height: 1230px;
  max-width:1060;
  display: flex;
  flex-direction: column;
    
}

.BackscreenInterscroller2{
  background-color: #fafafa !important; 
  min-height:auto !important;
   max-width:1060px !important;
   margin-top: 470px;
   
  
}

.Logo{
transform: scale(0.5);
display: flex;
align-items: center;

   justify-content: center;
}

.DivLogo{
  width: 100%;
  height: auto;
  
}


.Test{
  transform: scale(0.8);
  
}

.TextaboveLine{
  margin-top: 50px;
  font-size: 10pt;
  font-weight:700;
  color: black !important;
  width: 100%;
  text-transform: uppercase;
}

.Thickline{
  border: 2px solid black
}

.TextaboveLine2{
 /* font-size: 1.4vw; */
 font-size: 10pt;
  float: right;
  font-weight:bold;
  color: black !important;
  text-transform: uppercase;
  padding-top: 10px;
}

.TextaboveLine3{
  margin-top: 50px;
  font-size: 11pt;
  font-weight:normal;
  color: black !important;
}

.Minilogo{
  max-width: 25px;
  max-height: 25px;
}

.Image{
  
  width: 100%;
  height: 'auto';
  margin-top: 40px;
     
  }

  .Panel{
    border: 2px solid #d2d2d2 !important;
    width: 100%;
    height: 45px;
    margin: 0 auto;
    margin-top: 5%;
  }

  .ABO{
    background-color: #ee7f00 ;
    color: white;
    float: left;
    height: 25px;
    font-weight: bold;
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
    display: none;
  }
  .HamburgerMenu{
    float: right;
    margin-top: 25px;
    display: none;
    margin-bottom: 10px;
    width: 100%;
    opacity: 0;
  }
  .SkyscrapperMobile{
    display: none;
  }

  .MediumRectangleMobile{
    display: none;
  }

  .Interscroller{
    display: none;
  }

  .DesktopAds{
    display: block;
  }

  .Menu{
    position: absolute;
    top: 45%;
    
  }
  .Name{
    position: absolute;
    width: 100%;
    height: 100vh;
    align-items: center;
   display: flex;
   justify-content: center;
   flex-direction: column;
  
  }
  @import url('https://fonts.googleapis.com/css2?family=Trirong&display=swap');

  .Name p{
    position: absolute;
    font-size: 2vw;
    font-family: 'Trirong', serif;
    top: 28%;
  }
  .Name .Title{
    position: absolute;
    font-size: 3vw;
    font-family: 'Trirong', serif;
    top: 0;
    color:#ee7f00 
  }
  .Name .Bottom{
  
    font-size: 1vw;
    font-family: 'Trirong', serif;
   
  }

  @media  (max-width: 768px) {
    .Backscreen{
      background-color: #f2f2f2;
      height: 100vh;
      width:100%,
    }

    .ABO{
      background-color: #ee7f00 ;
      color: white;
      float: left;
      width: 100%;
      font-weight: bold;
      width: 50px;
      display: inline;
    }
    .HamburgerMenu{
      float: right;
      margin-top: 15px;
      opacity: 1;
      width: 100%;
    }

    .Logo{
      transform: scale(0.5);
      display: flex;
      width: 100%;
      height: auto;
      justify-content: center;
       
      }

      .DivLogo{
        width: 70%;
        height: auto;
         
      }

      .First3Picturies{
        display: none;
      }



      .TextaboveLine{
        margin-top: 50px;
        font-size: 8pt;
        font-weight:700;
        color: black !important;
        
      }
      
      .Thickline{
        border: 1px solid black
      }
      
      .TextaboveLine2{
        font-size: 5.5pt;
        float: right;
        font-weight:bold;
        color: black !important;
        text-transform: uppercase;
        padding-top: 10px;
      }
      
      .TextaboveLine3{
        margin-top: 50px;
        font-size: 11pt;
        font-weight:normal;
        color: black !important;
      }

      .Pictures4{
        display: none;
      }

      .HideElement{
        display: none;
      }

     .Test{
       margin-left: 10%;
     }

     .DesktopAds{
      display: none;
    }
  
    
  }

  

  @media  (max-width: 480px) {
    
    .DesktopAds{
      display: none;
    }
    
    .Interscroller{
      display: flex;
      flex-direction: column;
      height:490px;
      width: 100%;
    }

     .SkyscrapperMobile{
      display: flex;
      justify-content: center;
      

    }
    .Skyscrapper{
      display: none;
    }

    .MediumRectangleMobile{
      display: block;
      justify-content: center;
    }


    
  }

  .Butto{
    margin-top: 200px;
  }

  .Impressum{
   
    font-size: 1vw;
    font-family: 'Trirong', serif;
    color: black;
    font-weight: bold;
   
  }

  .Texto{
    width: 100%;
    margin-top: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }