.Footer{
    width: 100%;
    height: auto;
  
}

.Display{
   
    /*
      .Backscreen{
  background-color: #f2f2f2;
  min-height: 100vh;
  max-width:1060;
  display: flex;
    flex-direction: column;
    background-image: url('components/ads/components/Rollover.jpg');
    
    background-attachment: fixed;
    background-repeat: no-repeat;
 */
 width: 100%;
height: 3065vh;
    display: block;
    justify-content: center;
    position: absolute;
    
    background-image: url("./components/Rollover.jpg");
   background-size: cover;
    background-attachment:fixed !important
  }

  

  .Rollover{
      width: 100%;
      height: auto;
     
   
    
  }

  .DoppelSideBarRightpict{
  
    width: 50vh;
    height: 100vh;
    
   
    
}

.DoppelSideBarRight{
    
    
    width: 100%;
    
   position:sticky;
   top:0;
}

.DoppelSideBarRight1Lay{
    position: absolute;
    right: 0;
    height: 21000px;
    pointer-events: none;
  
}


.DoppelSideBarLeftpict{
  
    width: 50vh;
    height: 100vh;
   
    
}



.DoppelSideBarLeft{
    
    
    width: 100%;
    height: auto;
   position:sticky;
   top:0;
}

.DoppelSideBarLeft1Lay{
    position: absolute;
    left: 0;
    height: 21000px;
    pointer-events: none;
}



.Sidebar1Lay{
    position: absolute;
    right: 0;
    height: 21000px;
}

.Sidebar{

    width: 100%;
    height: auto;
   position:sticky;
   top:0;
}

.Sidebarpict{
    width: 100%;
    height: 100vh;
}


.RectangleDisplay{
   position: absolute;
   height: 80px;
}

.RectanglePict{
    width: 100%;
    height: inherit;
}


.MobileInterscroller{
    height:100vh;
    width: 100%;
    position: relative;
    
   
  
   
    /*clip-path: polygon(0% 0%, 100% 0%, 100% 55%, 0% 55%);*/
    
    }
    .MobileInterscrollerPict{
        position: fixed;
        top: 15%;
        z-index: -2;
        background-image: url("./components/Apple_Mobile_interscroller.jpg");
       
        background-repeat: no-repeat;
        background-size:contain;
         height: inherit;
        width: inherit;
    }

    .MobileInterscrollerPict2{
        position: fixed;
        top: 15%;
        z-index: -2;
        background-image: url("./components/Apple_Mobile_interscroller2.jpg");
       
        background-repeat: no-repeat;
        background-size:contain;
         height: inherit;
        width: inherit;
    }
   

@media  (max-width: 480px) {


    

    .Takeover1Lay{
        position: relative;
        height: auto;
        width: 100%;
    }

    .Takeover{
        position: relative;
        width: 100%;
        height: auto;
        margin-top: 50px;
        display: flex;
        justify-content: center;
    }
    
    
    
    .Takeoverpict{
        width: 300px;
        height: 250px;
    }

    .RectangleDisplay{
        display: none;
     }

     


       .Display{
        display: none;
      }
    
      .UniCreditMini{
        display: none;
      }
    
      .Rollover{
        display: none;
         
       
        
      }
    
      .DoppelSideBarRightpict{
      
        display: none;
       
        
    }
    
    .DoppelSideBarRight{
        
        
        display: none;
    }
    
    .DoppelSideBarRight1Lay{
        display: none;
    }
    
    
    .DoppelSideBarLeftpict{
      
        display: none;
        
    }
    
    .DoppelSideBarLeft{
        
        
        display: none;
    }
    
    .DoppelSideBarLeft1Lay{
        display: none;
        
    }
    
    
    
    .Sidebar1Lay{
        display: none;
    }
    
    .Sidebar{
    
        display: none;
    }
    
    .Sidebarpict{
       display: none;
    }
    


  }

  @media (max-width:768px){
      .Name{
          display:none;
      }
  }