.Minilogo{
  height: 18px;
  width: 18px;
  margin-bottom: -5px;
  margin-right: 5px;
}


.Head{
    
  display: flex;
  justify-content: center
    
   
}

.Date{
  position: relative;
  color: gray;
  cursor: pointer;

}

.NavPos{
  margin-top: -20px;
  margin-left: 80px;
}

.HeadSize{
  max-height: 1012px;
  word-break:break-all;
}
.Login{
  
   max-width: 100%;
  
  
}
.Abo{
  
  background-color: #ef7100;
  color: white;
  text-align: center;
  width: 40px;
  float: right;
 
}



.List{
    display: inline-flex;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}



.Minilogo{
  max-height: 20px;
  max-width: 20px;
  
}

.Navleft{
  float: left;
}

.Searchfield{
  max-width: 70px;
  max-height: 30px;
 

}

.PictureSize{
  max-width: 182px !important;
  max-height: 27px !important;
}

.FootLink{
  margin-top: 40px;
  width: 100% !important;
  float: left;
}

.SizeofText{
  color: black !important;
  font-size: 13px !important;
  pointer-events: none;
  width: 80%;
    margin-top: 20px;
}
.NavFoot{
  width: 100%;
 height: auto;
 word-break:break-all;
}
/*////////////////////////////////////////////////////////////////////////*/


@media  (max-width: 768px) {

  .NavFoot{
   display: none;
  }

  .HeadSize{
   display: none;
  }
  .Head{
    

    display: flex;
    justify-content: center
      
     
  }
  
  .Login{
     
     max-width: 100%;
    
    
  }
  .Abo{
    
    background-color: #ef7100;
    color: white;
    text-align: center;
    width: 40px;
    float: right;
   
  }
  


  
  
  .List{
      display: inline-flex;
      color: white;
      text-align: center;
      padding: 14px 16px;
      text-decoration: none;
  }
  
  .Date{
    float: left;
    
  }
  .Minilogo{
    max-height: 20px;
    max-width: 20px;
    
  }
  
  .Navleft{
    float: left;
  }
  
  .Searchfield{
    max-width: 70px;
    max-height: 30px;
   
  
  }
  
  .PictureSize{
    max-width: 182px !important;
    max-height: 27px !important;
    margin-top: 40px !important;
  }
  
  .FootLink{
    margin-top: 40px;
    width: 100% !important;
    float: left;
  }
  
  .SizeofText{
    color: black !important;
    font-size: 13px !important;
    margin-top: 40px !important;
    width: 100% !important;
    float: left;
    pointer-events: none;
  }

  
}

/*New Styles updated*/

.Nav{
  
  width: 100%;

    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
}

.Category{
  display: flex;
  width: 55%;
  justify-content: space-evenly;
  color: gray;
  cursor: pointer;

  
}

.Buttons{
  display: flex;
  width: 10%;
  justify-content: space-between;
  cursor: pointer;
}

.LoginBtn{
  color: orange;
  cursor: pointer;
}

.LoginBtn:hover{
  text-decoration: underline;
  }

#Newsletter:hover{
text-decoration: underline;
}
#Podcasts:hover{
  text-decoration: underline;
}
#Club:hover{
  text-decoration: underline;
}
#ePaper:hover{
  text-decoration: underline;
}
#Archiv:hover{
  text-decoration: underline;
}
#Shop:hover{
  text-decoration: underline;
}
#Jobs:hover{
  text-decoration: underline;
}
#Inside:hover{
  text-decoration: underline;
}