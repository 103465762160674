@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.MainArticle{
    width: inherit;
    max-height: 526px;
    height: 100%;
  
    display: flex;
    cursor: pointer;
}

.ArticleText{
   position: relative;
  
    width: 50%;
}



.ArticleImg{
    max-height: 520px;
    max-width: 100%;
    width: 100%;
    height: 520px;
 
    position: relative;

    img{
        width: 95%;
        position: absolute;
        right: 0;
        top: 70px;
    }
}



.ArticleDesr{
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 1.6rem;
    margin-top: 70px;
}

.ArticleDesr:hover{
    text-decoration: underline;
    text-decoration-color: orange;
    text-decoration-thickness: 5%;
   
 }

.ArticleContent{
    font-size: .900rem;
    font-weight: 500;

}


.ArticleIcons{
    display: flex;
    margin-top: 25px;
}

.IconandText{
    display: flex;
    font-size: 12px;
    color: gray;
    margin-left: 8px;
}


.ThreeArticlesTakeover{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: baseline;
    
}