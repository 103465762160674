.threeArticles{
    width: 90%;
    cursor: pointer;
}

.Img{
    width: inherit;
}

.thrArtcImg{
    width: inherit;
    height: 160px;
   
}

.Title{
    width: 70%;
    font-weight: 800;
}

.Desription{
    margin-top: 20px;
    width: 75%;
}




.MainArticles{
    opacity: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    cursor: pointer;
}

.MainImg{
    opacity: 1;
    width: inherit;
}
.ImgSmall{
    opacity: 1;
    width: inherit;
    margin-left: 30px;
}


.Img{
    opacity: 1;
    //width: inherit;
    width: 97%;
}

.MainTitle{
    opacity: 1;
    width: 70%;
    font-weight: bold;
    font-size: 27px;
    cursor: pointer;
}

.MainTitle:hover{
    text-decoration: underline;
    text-decoration-color: orange;
    text-decoration-thickness: 5%;
   
 }

.MainContent{
    opacity: 1;
    width: 70%;
    font-size: 15px;
    margin-top: 10px;
    cursor: pointer;
}


.SmallArticle{
    opacity: 1;
    display: flex;
    flex-direction: row-reverse;

    margin-top: 20px;
    cursor: pointer;
}

.ImgSmallArticle{
    width: 80%;
}

.SmallMainTitle{
    opacity: 1;
    font-weight: bold;
    font-size: 27px;
}

.SmallMainTitle:hover{
    text-decoration: underline;
    text-decoration-color: orange;
    text-decoration-thickness: 5%;
   
 }


.SmallMainContent{
    opacity: 1;
    margin-top: 10px;
}

.TitleandContent{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 70%;
}


.HeadScroll{
    width: 100%;
    height: 100px;
    background: white;
    position: sticky;
    z-index: 1;
    top: 0;
    display: none;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 2px 4px;
    transform: translateY(-200px);
;
    
}

.MenuHome{
    width: 10%;
    display: flex;
    justify-content: space-evenly;
    cursor: pointer;
}

.SocialMedia{
   
    width: 15%;
    display: flex;
    justify-content: space-evenly;
    cursor: pointer;
}

.PersonSearch{
    
    width: 10%;
    display: flex;
    justify-content: space-evenly;
    cursor: pointer;
}

.ImgHead{
    cursor: pointer;
}

.Links{
 
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-around;
    font-size: 13px;
    color: gray;
    
    
}

.Column{
    
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    cursor: pointer;
}

.Headline{
    text-transform: uppercase;
    color: black;
    font-weight: bold;
    font-size: 20px;
    padding-bottom: 25px;
}

#column{
    margin-top: 55px;
}